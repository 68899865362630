import React from 'react'
import BackVid from '../../img/backvid.mp4'
import BackVid2 from '../../img/backvid.webm'
import Head1 from '../../img/Headshot.avif'
import Head2 from '../../img/Headshot2.webp'
function Reviews() {
    return (
        <section class="section-stories">
            <div class="bg-video">
                <video class="bg-video__content" autoPlay muted loop>
                    <source src={BackVid} type="video/mp4" />
                    <source src={BackVid2} type="video/webm" />
                    Your browser is not supported!
                </video>
            </div>

            <div class="u-center-text u-margin-bottom-medium">
                <h2 class="heading-secondary">
                    We make people genuinely happy
                </h2>
            </div>

            <div class="row" data-aos="fade-up">
                <div class="story">
                    <figure class="story__shape">
                        <img src={Head1} alt="Person on a tour" class="story__img" />
                        <figcaption class="story__caption">Mary Eniki</figcaption>
                    </figure>
                    <div class="story__text">
                        <h3 class="heading-tertiary u-margin-bottom-small">Harmony Shipping- Always has my back. Cannot be happier.</h3>
                        <p>
                            Well, for me Harmony is nothing short for excellent work. They're my go to shipping agent. They're honest, transparent, keep to their word, no short cuts and most of all, stress free to work with. All it takes for me is a couple of emails and everything is done and before I know it, my vehicles are in Walvis Bay. I mean, how easy can life be with Entity by one's side?
                        </p>
                    </div>
                </div>
            </div>

            <div class="row" data-aos="fade-up">
                <div class="story">
                    <figure class="story__shape">
                        <img src={Head2} alt="Person on a tour" class="story__img" />
                        <figcaption class="story__caption">Kiosha Humki</figcaption>
                    </figure>
                    <div class="story__text">
                        <h3 class="heading-tertiary u-margin-bottom-small">Looking forward to using Harmony Shipping again.</h3>
                        <p>
                            Excellent service by David and the rest of the Entity Dockers team.
                            We have sent a Routemaster Double Decker bus and 3 cars from Japan to Ghana between 2017 and June 2021 and Ascope has been brilliant. Vehicles all arrived safely.
                            Jacob has always given me timely updates.
                            Entity Dockers has always given favourable prices.
                        </p>
                    </div>
                </div>
            </div>

            <div class="u-center-text u-margin-top-huge">
                <button class="btn btn--basic">Read all stories &rarr;</button>
            </div>
        </section>
    )
}

export default Reviews